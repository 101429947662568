<template>
  <div>
    <v-card>
      <div class="d-flex flex-column assesmentBox">
        <TopBar
          :minutes="assessmentModel.Questions.reduce((total, a) => total + a.Duration, 0)"
          :currentQuestionNumber="currentQuestionNumber"
          :questionCount="questionCount"
        >
        </TopBar>
        <div class="titleBox">Enter the Correct Answer</div>
        <div class="d-flex flex-column justify-space-between ga-16">
          <QuestionBox :title="selectedQuestion?.Question"></QuestionBox>
          <div style="width: 100%">
            <v-dialog v-model="showEditor" fullscreen hide-overlay :scrollable="false" content-class="assessment-editor"
              ><AssessmentEditor
                :Question="selectedQuestion"
                :assessmentId="assessmentModel.Id"
                :courseId="courseId"
                :roadMapId="roadMapId"
                @Submitted="QuestionSubmitted"
                v-if="selectedQuestion"
              ></AssessmentEditor
            ></v-dialog>
            <v-btn v-if="hasEditor" @click="setShowEditor">Please click to open editors</v-btn>
            <AnswerBox :options="[1, 2, 3, 4]" v-if="!hasEditor"></AnswerBox>
          </div>
        </div>
        <div class="d-flex justify-end ga-2 pt-5">
          <v-btn class="back_btn" dark @click="backQuestion" :disabled="!enabeldBack"> <v-icon size="x-large">mdi-chevron-left</v-icon> Back </v-btn>
          <v-btn class="next_btn" dark @click="nextQuestion" :disabled="!enabeldNext"> Next <v-icon size="x-large">mdi-chevron-right</v-icon> </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TopBar from "shared-components/src/components/Assessment/TopBar.vue";
import QuestionBox from "shared-components/src/components/Assessment/QuestionBox.vue";
import AnswerBox from "shared-components/src/components/Assessment/AnswerBox.vue";
import AssessmentEditor from "./AssessmentEditor.vue";
import { TMRoadMapApi, ClientAssessmentModel } from "shared-components/src/services/openApi/api";
import AssessmentModel, { Question } from "shared-components/src/models/AssessmentModel";
import moment from "moment";
export default defineComponent({
  components: {
    TopBar,
    QuestionBox,
    AnswerBox,
    AssessmentEditor,
  },
  props: {
    assessmentModel: {
      type: Object as () => AssessmentModel,
      required: true,
    },
    courseId: {
      type: String,
    },
    roadMapId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentQuestionNumber: 0,
      questionCount: 0,
      questionList: [],
      selectedQuestion: null as Question | null,
      enabeldNext: true,
      enabeldBack: false,
      hasEditor: false,
      showEditor: false,
      TMRoadMapApi: new TMRoadMapApi(),
    };
  },
  methods: {
    QuestionSubmitted(answer: string) {
      var index = this.assessmentModel.Questions.findIndex((item) => item.Id == this.selectedQuestion?.Id);
      if (index != -1) {
        this.assessmentModel.Questions[index].TalentAnswer = answer;
        this.assessmentModel.Questions[index].Status = "Submitted";
      }
      // this.showEditor = false;
      this.selectedQuestion = null;
    },
    nextQuestion() {
      this.currentQuestionNumber += 1;
      this.selectedQuestion = this.assessmentModel.Questions[this.currentQuestionNumber - 1];
      this.hasEditor = this.selectedQuestion.Editors.length > 0;
      if (this.currentQuestionNumber === this.questionCount) {
        this.enabeldNext = false;
      }
      if (this.currentQuestionNumber > 1) {
        this.enabeldBack = true;
      }
    },
    backQuestion() {
      this.currentQuestionNumber -= 1;
      this.selectedQuestion = this.assessmentModel.Questions[this.currentQuestionNumber - 1];
      this.hasEditor = this.selectedQuestion.Editors.length > 0;
      if (this.currentQuestionNumber !== this.questionCount) {
        this.enabeldNext = true;
      }
      if (this.currentQuestionNumber === 1) {
        this.enabeldBack = false;
      }
    },
    startQuestion(question: Question) {
      this.selectedQuestion = question;
      // this.showEditor = true;
    },
    setShowEditor() {
      this.showEditor = true;
    },
  },
  mounted() {
    this.questionCount = this.assessmentModel.Questions.length;
    this.nextQuestion();
    // this.TMRoadMapApi.getTeammemberRoadMapAssessmentDetails(,32)
  },
});
</script>
<style scoped lang="scss">
.titleBox {
  padding-top: 20px;
  padding-bottom: 15px;
}
.assesmentBox {
  padding: 20px;
  background-color: $c_night;
}

.next_btn {
  background: $c_bittersweet !important;
  color: $c_night;

  &:hover {
    background: $c_imperial_red !important;
  }
}

.back_btn {
  text-transform: unset;
  background: $c_night !important;
  color: $c_bittersweet;
  border-color: $c_bittersweet;
  border-width: 1px;
  &:hover {
    background: $c_jet_gray !important;
  }
}
</style>
