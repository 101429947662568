<template>
  <v-card>
    <v-card-title> Add new access </v-card-title>
    <v-card-text>
      <v-form ref="frmAccess" v-model="valid" lazy-validation class="form-inputs">
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="6">
            <ComboBoxField
              label="Project"
              placeholder="Project"
              :items="projectList"
              item-title="Name"
              item-value="id"
              :rules="[rules.required]"
              v-model="model.project"
              outlined
            />
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6">
            <ComboBoxField
              label="Team Member"
              placeholder="Team Member"
              :items="teammemberList"
              item-title="Name"
              item-value="id"
              :rules="[rules.required]"
              v-model="model.teamMember"
              outlined
            />
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6">
            <TextField label="Email" placeholder="Email" v-model="model.email" :rules="[rules.required, rules.email]" outlined />
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6">
            <TextField label="Tool" placeholder="Tool" v-model="model.tool" :rules="[rules.required]" outlined />
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6">
            <TextField label="URL" placeholder="URL" v-model="model.url" :rules="[rules.required, rules.url]" outlined />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12">
            <TextAreaField label="NOTES" placeholder="NOTES" v-model="model.notes" height="90px" outlined />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="pageLoading" class="secondary_btn" dark @click="cancel">cancel</v-btn>
      <v-btn :loading="pageLoading" class="primary_btn" dark @click="save"><v-icon>mdi-check</v-icon> Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["projects", "teammembers", "loading"],
  data() {
    return {
      valid: true,
      pageLoading: false,
      projectList: [] as Array<{
        id: string;
        Name: string;
      }>,
      teammemberList: [] as Array<{
        id: string;
        Name: string;
      }>,
      model: {
        teamMember: null as {
          id: string;
          Name: string;
        } | null,
        project: null as {
          id: string;
          Name: string;
        } | null,
        tool: null as string | null,
        url: null as string | null,
        notes: null as string | null,
        email: null as string | null,
      },
      rules: {
        required: (value: any) => !!value || "Required.",
        url: (v: any) =>
          !v ||
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi.test(v) ||
          "URL must be valid.",
        email: (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid.",
      },
    };
  },
  watch: {
    projects(newVal) {
      this.projectList = newVal;
    },
    teammembers(newVal) {
      this.teammemberList = newVal;
    },
    loading(newVal) {
      this.pageLoading = newVal;
    },
  },
  async mounted() {
    await this.fetchTeammembers();
    await this.fetchProjects();
  },
  methods: {
    fetchTeammembers(): void {
      this.$emit("fetchTeammembers");
    },
    fetchProjects(): void {
      this.$emit("fetchProjects");
    },
    resetModel(): void {
      this.model = {
        teamMember: null,
        project: null,
        tool: null,
        url: null,
        notes: null,
        email: null,
      };
      (this.$refs.frmAccess as any).reset();
    },
    cancel() {
      this.resetModel();
      this.pageLoading = false;
      this.$emit("cancel");
    },
    async save(): Promise<void> {
      const isValid = await (this.$refs.frmAccess as any).validate();
      if (isValid.valid) {
        this.pageLoading = true;
        await this.$emit("save", this.model);
      }
    },
  },
});
</script>
