<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>{{ positionDetails.Title != undefined ? `Position Title: ${positionDetails.Title}` : ""  }}</v-card-title>
          <v-card-text>
            <v-container>
              <v-row gutter="4">
                <v-col cols="12">
                  <p>Job Titles: {{ positionDetails.JobTitles?.length != 0 ?  positionDetails.JobTitles?.join(", ") : "undefined" }}</p>
                </v-col>
                <v-col cols="12">
                  <p>Job Description: {{ positionDetails.JobDescription ?? "undefined" }}</p>
                </v-col>
                <v-col cols="12">
                  <p>Skills: {{ positionDetails.Skills?.length != 0 ? positionDetails.Skills?.join(", ") : "undefined" }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="onCancel">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="onSave">Apply</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PositionApi, TeammemberPositionResponseModel } from "shared-components/src/services/openApi";
import Utils from "shared-components/src/utils/Utils"
import store from "@/store";


export default defineComponent({
  name: "ConfirmationPage",
  data() {
    return {
      deepLinkId: "" as string,
      positionDetails: {} as TeammemberPositionResponseModel,
      sourceOfRequest: "" as string,
    };
  },
  async mounted() {
    const url = window.location.href;
    this.deepLinkId = url.split('/').pop() ?? "";
    if (this.deepLinkId) {
      try {
        this.positionDetails = (await new PositionApi().getPositionDetailsForTeammemberApplication(this.deepLinkId)).data;
      } finally { }
    }
  },
  methods: {
    goToProfilePage() {
      this.$router.push("/team-member/profile");
      window.location.href = "/team-member/profile";
    },
    async onCancel() {
      store.dispatch("showErrorMessage", "You cancelled your application for the position");
      await new Promise(resolve => setTimeout(resolve, 4000));
      this.goToProfilePage();
    },
    async onSave() {
      if (this.deepLinkId && this.positionDetails.PositionId) {
        try {
          var rsp = await new PositionApi().applyForPosition(this.deepLinkId, this.positionDetails.PositionId);
          store.dispatch("showSuccessMessage", rsp.data);
          await new Promise(resolve => setTimeout(resolve, 4000));
          this.goToProfilePage();
        } finally { }
      }
    },
  },
});
</script>
