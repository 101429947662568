import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.positionDetails.Title != undefined ? `Position Title: ${_ctx.positionDetails.Title}` : ""), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_container, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { gutter: "4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", null, "Job Titles: " + _toDisplayString(_ctx.positionDetails.JobTitles?.length != 0 ?  _ctx.positionDetails.JobTitles?.join(", ") : "undefined"), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", null, "Job Description: " + _toDisplayString(_ctx.positionDetails.JobDescription ?? "undefined"), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", null, "Skills: " + _toDisplayString(_ctx.positionDetails.Skills?.length != 0 ? _ctx.positionDetails.Skills?.join(", ") : "undefined"), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, { onClick: _ctx.onCancel }, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode("Cancel")
                        ])),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, { onClick: _ctx.onSave }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("Apply")
                        ])),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}